<template>
  <div class="visualization-header">
    <span><i :class="icon"/></span>
      {{ config.productName.split(/(?=[A-Z])/).join(" ").toUpperCase() }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VisualizationHeader',
  props: {
    icon: {
      type: String,
      default: 'icon icon-greyhound-races-v',
    },
  },
  computed: {
    ...mapGetters([
      'config',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

.visualization-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background-color: rgb(29, 33, 36);
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: var(--text-primary-1);
  pointer-events: none;
  font-family: 'Nunito', sans-serif;
  border-bottom: 2px solid rgb(122, 124, 126);
  .icon {
    font-size: 22px;
    padding-right: 10px;
    line-height: 30px;
    opacity: .7;
  }
}
</style>
